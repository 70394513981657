@import "~antd/dist/antd.dark.css";

:root {
  --text-primary: #fff;
  --text-darkBg: #000;
  /* --bgPrimary: #091328; */
  --bgPrimary: #000000;
  --bgSecondary: #091328;
  --pink: #0071bd;
  --light-blue: #e6f7ff;
  --border: #d9d9d9;
  --LinkHoverColor: var(--blue);
}

/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app {
  display: flex;
  overflow: hidden;
}

.navbar {
  flex: 0.2;
  background-color: rgb(0, 21, 41);
}
.main {
  flex: 0.8;
  width: 100%;
}
.routes {
  padding: 20px;
}

.nav-container {
  position: fixed;
  left: 0;
  margin: 10px;
  height: 100vh;
  margin: 0px;
  background-color: rgb(0, 21, 41);
}

.logo-container {
  background-color: #001529;
  display: flex;
  padding: 20px;
  align-items: center;
  width: 100%;
}

.logo a {
  color: white;
}
.menu-control-container {
  display: none !important;
  position: absolute !important;
  right: 10px !important;
  top: 25px !important;
  font-size: 1.2rem !important;
  background-color: var(--bgSecondary) !important;
  border: none !important;
}

@media screen and (max-width: 1300px) {
  .main {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1170px) {
  .main {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1000px) {
  .main {
    margin-left: 100px;
  }
}

@media screen and (max-width: 800px) {
  .app {
    flex-direction: column;
    overflow: hidden;
  }

  .navbar {
    flex: 1;
  }

  .main {
    flex: 1;
    margin-top: 90px;
    margin-left: 0px;
    margin-right: 10px;
  }

  .nav-container {
    height: 8vh;
    position: fixed;
    width: 100%;
    z-index: 100;
    background-color: var(--bgSecondary);
  }

  .menu-control-container {
    display: block !important;
  }

  .ant-menu {
    position: absolute;
    right: 0px;
  }
}

.exchange-image {
  margin: 0px 10px !important;
}
