.select-news {
  width: 180px;
}
.news-card {
  min-height: 300px !important;
}
.news-image-container {
  display: flex !important;
  justify-content: space-between !important;
}
.news-title {
  width: 70%;
}

.news-image-container .img {
  width: 100px;
  height: 100px;
}
.news-card p {
  color: white;
  margin: 10px 0px !important;
}
.provider-container {
  display: flex;
  justify-content: space-between;
}
.provider-name {
  margin-left: 10px;
}
