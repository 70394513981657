.coin-value-statistics-heading p {
  font-size: 1rem;
  opacity: 0.9;
}

.coin-stats {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--border);
  font-size: 1rem;
  opacity: 0.9;
  padding: 20px;
}

.coin-stats-name {
  display: flex;
  gap: 10px;
  font-size: 1rem;
}

.coin-stats:hover {
  background-color: var(--bgSecondary);
}
