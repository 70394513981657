.site-layout-content {
  min-height: 280px;
  padding: 24px;
}

.layout-container {
  color: var(--text-primary);
  background-color: var(--bgPrimary);
}

.footer {
  background-color: #001529;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
}
